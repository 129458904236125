import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.scss";
import Main from "./containers/Main";
import PortfolioApprentissage from "./components/portfolioApprentissage/PortfolioApprentissage";

function App() {
  return (
    <Router>
      <div>
        <Routes> {/* Les composants Route doivent être enfants de Routes */}
          <Route path="/" element={<Main />} />
          <Route path="/PortfolioApprentissage" element={<PortfolioApprentissage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
