import  React from 'react';
import './PortfolioApprentissage.scss'; // Assurez-vous d'avoir le bon chemin

const PortfolioApprentissage = () => {
  return (
    <section className="portfolio-apprentissage">
      <div className="container">
        <h1>Portfolio d'Apprentissage</h1>
        <div className="content-section">
          <h2>Introduction</h2>
          <p>Au cours de mon BUT informatique, j'ai pu développer un large éventail de compétences en développement web,
            en gestion de projet et en communication. Je me suis orienté vers le parcours IAMSI, qui m'a permis
            d'acquérir des compétences spécifiques en intégration d'applications et management des systèmes
            d'information.</p>
        </div>

        <div className="content-section">
          <h2>C2 - Optimiser des applications</h2>

          <h3>Composante essentielle 1 : Appréhender et construire des algorithmes</h3>

          <h4>AC 1 : Analyser un problème avec méthode (découpage en éléments algorithmiques simples, structure de
            données…)</h4>
          <p>Lors du développement de "Les Aventuriers du Rail - Monde", j'ai décomposé le jeu en éléments
            algorithmiques simples.
            Par exemple, pour gérer le placement des wagons sur le plateau, j'ai créé des fonctions Java distinctes
            représentant chaque action du joueur,
            permettant ainsi une structure de code claire et facilement testable.</p>

          <h4>AC 3 : Formaliser et mettre en œuvre des outils mathématiques pour l’informatique</h4>
          <p>Dans le Sic Bo, l'utilisation de probabilités pour simuler le lancer des dés a nécessité l'application de
            connaissances mathématiques.
            J'ai développé une fonction en JavaScript qui génère des résultats aléatoires de dés en se basant sur une
            distribution uniforme,
            simulant ainsi de manière réaliste le hasard des jeux de casino.</p>

          <h3>Composante essentielle 2 : Sélectionner les algorithmes adéquats pour répondre à un problème donné</h3>

          <h4>AC 1 : Choisir des structures de données complexes adaptées au problème</h4>
          <p>Pour optimiser le stockage des routes dans "Les Aventuriers du Rail - Monde", j'ai utilisé des graphes en
            Java,
            permettant de calculer efficacement les itinéraires les plus courts grâce à l'algorithme de Dijkstra,
            améliorant ainsi l'expérience de jeu en offrant des suggestions d'itinéraires aux joueurs.</p>

          <h4>AC 3 : Comprendre les enjeux et moyens de sécurisation des données et du code</h4>
          <p>La réalisation du projet Sic Bo m'a confronté à l'importance de sécuriser les communications entre le client et le serveur.
            En utilisant PHP pour la gestion du backend, j'ai mis en place des mesures de sécurisation telles que le filtrage des entrées utilisateurs
            pour prévenir les injections SQL, assurant ainsi l'intégrité de la base de données du jeu.</p>
        </div>

        <div className="content-section">
          <h2>C3 - Administrer des systèmes informatiques communicants complexes</h2>

          <h3>Composante essentielle 1 : Installer et configurer un poste de travail</h3>

          <h4>AC 1 : Identifier les différents composants (matériels et logiciels) d’un système numérique</h4>
          <p>Le développement des interfaces utilisateur pour les deux projets a nécessité une compréhension approfondie des environnements
            de développement intégrés (IDE), comme IntelliJ IDEA pour Java et WebStorm pour le développement web.
            J'ai configuré ces outils pour optimiser le workflow de développement, y compris l'intégration de plugins pour la gestion de versions avec Git.</p>

          <h4>AC 3 : Installer et configurer un système d’exploitation et des outils de développement</h4>
          <p>L'installation d'environnements de développement local pour le Sic Bo, comprenant un serveur Apache, PHP et une base de données MySQL,
            a été réalisée en utilisant MAMP. Cela m'a permis de simuler fidèlement l'environnement de production et de tester le jeu dans des conditions réelles.</p>

          <h3>Composante essentielle 2 : Déployer des services dans une architecture réseau</h3>

          <h4>AC 1 : Concevoir et développer des applications communicantes</h4>
          <p>La synchronisation du gameplay entre plusieurs joueurs dans "Les Aventuriers du Rail - Monde" via une interface réseau a illustré
            ma capacité à créer des applications communicantes. En utilisant des sockets Java, j'ai établi une communication en temps réel entre
            clients et serveur, permettant des interactions fluides et synchronisées entre joueurs.</p>

          <h4>AC 3 : Sécuriser les services et données d’un système</h4>
          <p>Pour le Sic Bo, j'ai mis en œuvre des protocoles HTTPS pour sécuriser les échanges de données entre le client et le serveur,
            utilisant des certificats SSL pour chiffrer les communications. Cela a renforcé la confiance des utilisateurs en protégeant leurs
            données contre les interceptions.</p>
        </div>

        <div className="content-section">
          <h2>C4 - Gérer des données de l'information</h2>

          <h3>Composante essentielle 1 : Concevoir et mettre en place une base de données à partir d’un cahier des charges client</h3>
          <h4>AC 1 : Mettre à jour et interroger une base de données relationnelle (en requêtes directes ou à travers une application)</h4>
          <p>Pour le Sic Bo, une base de données relationnelle a été conçue pour gérer les informations des joueurs, les scores, et les historiques de partie.
            J'ai utilisé PHP pour créer des requêtes SQL permettant de mettre à jour les scores après chaque partie et d'interroger la base de données
            pour afficher les meilleurs scores sur le site web, offrant ainsi une expérience interactive et compétitive aux utilisateurs.
          </p>

          <h4>AC 3 : Concevoir une base de données relationnelle à partir d’un cahier des charges</h4>
          <p>Le développement de "Les Aventuriers du Rail - Monde" nécessitait la gestion des différents états du jeu,
            incluant les cartes en main des joueurs, les routes construites, et les objectifs. Une base de données relationnelle a été
            créée pour refléter ces relations complexes, avec des schémas normalisés pour optimiser le stockage et la récupération des données,
            en suivant le cahier des charges initial.</p>

          <h3>Composante essentielle 2 : Optimiser une base de données, interagir avec une application et mettre en œuvre la sécurité</h3>

          <h4>AC 2 : Assurer la confidentialité des données (intégrité et sécurité)</h4>
          <p>La sécurisation des données était primordiale dans le projet Sic Bo, notamment pour la gestion des comptes utilisateurs.
            Des mesures telles que le hachage des mots de passe avant leur stockage en base de données ont été mises en œuvre.
            J'ai également utilisé des préparations de requêtes SQL pour prévenir les injections SQL, garantissant ainsi l'intégrité et
            la confidentialité des données des utilisateurs.</p>

          <h4>AC 3 : Organiser la restitution de données à travers la programmation et la visualisation</h4>
          <p>Pour "Les Aventuriers du Rail - Monde", l'interface utilisateur affiche dynamiquement l'état actuel du jeu,
            incluant le plateau de jeu et les cartes des joueurs. Ceci a été réalisé en extrayant et en restituant les données de la base de données
            à l'interface utilisateur via JavaFX. Des visualisations telles que la mise en évidence des routes possédées et des objectifs atteints
            ont été implémentées pour enrichir l'expérience de jeu.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PortfolioApprentissage;
